import { createRoot } from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { persistor, store } from 'store';
import 'assets/scss/style.scss';
import config from './config';
import { AuthProvider } from './context/AuthContext';
import { PersistGate } from 'redux-persist/integration/react';
import { CircularProgress } from '@mui/material';

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <PersistGate loading={<CircularProgress color={"info"} size={120}/>} persistor={persistor}>
            <AuthProvider>
                <BrowserRouter basename={config.basename}>
                        <App/>
                </BrowserRouter>
            </AuthProvider>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
