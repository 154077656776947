import {lazy} from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from "../layout/MinimalLayout";

// Importación de componentes
const NotFound = Loadable(lazy(() => import('views/pages/not-found')));
const ScheduleScreen = Loadable(lazy(() => import('views/pages/schedule')));
const AllEventsView = Loadable(lazy(() => import('views/pages/schedule/components/AllEventsView')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MinimalLayout/>,
    children: [
        {
            path: '*',
            element: <NotFound/>
        },
        {
            path: '/',
            element: <ScheduleScreen/>
        },
        {
            path: '/:eventIdentifier',
            element: <ScheduleScreen/>
        },
        {
            // Ruta para la vista completa de eventos de un tipo específico
            path: '/grid/:eventType',
            element: <AllEventsView/>
        },
        {
            // Ruta para la vista completa de eventos de un tipo específico
            path: '/all/:eventType',
            element: <AllEventsView/>
        },

    ]
};

export default MainRoutes;